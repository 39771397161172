<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class="hover"><a href="javascript:void(0)">门店详情</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>
            <li class="up"><span class="import">门店名称：</span><input type="text" v-model="form.storeName" :disabled="!MmsData.new"/> <small>设定后不可修改</small></li>
            <li class="up"><span class="import">区域(省)：</span>
              <select @change="sel('city')" v-model="form.storeProvince">
                <option v-for="item in provinceList" :key="item.provinceCode" :value="item.provinceName">{{item.provinceName}}</option>
              </select>
            </li>
            <li class="up"><span class="import">区域(市)：</span>
              <select @change="sel('county')" v-model="form.storeCity">
                <option v-for="item in cityList" :key="item.cityCode" :value="item.cityName">{{item.cityName}}</option>
              </select>
            </li>
            <li class="up"><span class="import">区域(县)：</span>
              <select v-model="form.storeCounty">
                <option v-for="item in countyList" :key="item.countyCode" :value="item.countyName">{{item.countyName}}</option>
              </select>
            </li>
            <li class="up"><span>详细地址：</span><input type="text" v-model="form.storeAddress"/></li>
            <li class="up"><span>联系电话：</span><input type="text" v-model="form.iPhone"/></li>
            <li class="up"><span>备注信息：</span><textarea v-model="form.memo"></textarea></li>
          </ul>
          <div class="center_b_4">
            <button class="settab" @click="subData">保存</button>
            <button class="remole" @click="closedWindow">取消</button>
          </div>
        </div>
    </RightWindow>
  </div>

</template>

<script>
import {city} from "@/assets/js/city"
import axios from '@/http/axios'
import {testStr,changeStr,deepCopy} from '@/utils/util'
export default {
  name: 'Info',
  props:{
    MmsData:Object,
    title:String,
  },
  watch:{
    'MmsData':{
      handler(newD){
        if(newD.new){
          this.form=deepCopy(this.defform)
        }else{
          this.form = deepCopy(newD.data)
          this.sel('city')
          this.sel('county')
        }
      },
      deep:true
    }
  },
  data() {
    return {
      provinceList:[],
      cityList:[],
      countyList:[],
      form:{
        storeName:'',
        storeProvince:'',
        storeCity:'',
        storeCounty:'',
        dianzhang:'',
        storeAddress:'',
        memo:'',
        iPhone:'',
      },
      defform:{
        storeName:'',
        storeProvince:'',
        storeCity:'',
        storeCounty:'',
        dianzhang:'',
        storeAddress:'',
        memo:'',
        iPhone:'',
      }
    }
  },
  created() {
    this.init()
  },
  components: {},
  mounted() {},
  methods: {
    Str(str){
      if(str) return changeStr(str)
      return '无'
    },
    subData(){
      if(testStr(this.form.storeName)){
        this.$Mesg({
          content: '门店名称不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.storeProvince)){
        this.$Mesg({
          content: '请选择门店所在区域（省）',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.storeCity)){
        this.$Mesg({
          content: '请选择门店所在区域（市）',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.storeCounty)){
        this.$Mesg({
          content: '请选择门店所在区域（县/区）',
          errOrSuc: 'war',
        })
        return
      }
      axios.post(this.MmsData.new?'/store/add':'/store/edit',this.form).then((res) => {
        if(res.code===5000){
          this.$Mesg({content: this.MmsData.new?'新增门店成功！':'修改门店成功！'})
          this.closedWindow()
          this.$parent.getData()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    sel(str){
      if(str==="city"){
        let obj=city.filter(e=>e.provinceName==this.form.storeProvince)
        this.cityList=obj.length?obj[0].cities:[]
        this.countyList=[]
      }else if(str==="county"){
        let obj=this.cityList.filter(e=>e.cityName===this.form.storeCity)
        this.countyList=obj.length?obj[0].counties:[]
      }
    },
    init(){
      this.provinceList=city.map((e)=>{
        const {provinceCode,provinceName}=e
        return {provinceCode,provinceName}
      })
      if(!this.MmsData.new){
        this.form = deepCopy(this.MmsData.data)
        this.sel('city')
        this.sel('county')
      }
    },
    closedWindow(){
      this.$emit('closedWindow')
    },
  },
}
</script>
