<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li>
          <p>门店名称：</p>
          <input type="text" @keypress.enter="getData" v-model="form.storeName">
        </li>
        <li>
          <p>编号：</p>
          <input type="text" @keypress.enter="getData" v-model="form.storeId">
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <thead>
          <tr>
            <td class="cen">编号</td>
            <td>门店名称</td>
            <td>到期时间</td>
            <td>所属区域</td>
            <td>门店地址</td>
            <td>联系电话</td>
            <td class="cen">剩余短信数量</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id" :class="countDate(item.daoqishijian)">
            <td class="cen">{{item.id}}</td>
            <td>{{item.storeName}}</td>
            <td>{{getDate(item.daoqishijian)}}</td>
            <td>{{item.storeProvince}}-{{item.storeCity}}-{{item.storeCounty}}</td>
            <td>{{item.storeAddress}}</td>
            <td>{{item.iPhone}}</td>
            <td class="cen">{{item.mesNum}}</td>
            <td>
              <div class="buttonGroup">
                <button type="button" class="suc" @click="setInfoData(item)" v-pow:2102>管理</button>
                <button type="button" class="new" @click="addSMS(item)">充值短信</button>
                <button type="button" class="gre" @click="payStore(item)">{{($store.state.User.daoqishijian>=0)?'续费VIP':'开通VIP'}}</button>
                <button type="button" class="err" @click="batchSetting(item)" v-if="$store.state.User.daoqishijian>=0" v-pow:2102>会员批量设置</button>
              </div>
            </td>
          </tr>
          
        </tbody>
      </table>
      <div class="LoyiSelectNoData" v-if="dataList.length==0">暂无数据</div>
    </div>
    </div>
    <div class="list_c">
      <div class="c_buttom">
        <button type="button" class="suc" @click="newStoreAdd" v-pow:2101>新增门店<img src="@/assets/images/vip.png"></button>
      </div>
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
    <Info v-if="infoShow" :title="title" :MmsData="MmsData" @closedWindow="closedWindow"></Info>
    <Pay v-if="payShow" @closdMegAndRF="payShow=false;getData()" @closdMeg="payShow=false" :Store="Store" :itemName="itemName" :SonId="SonId"></Pay>
    <div class="meg_all" v-if="vipShow">
      <div class="meg xxMeg">
        <h1>门店会员批量设置</h1>
        <div class="meg_a">
          <div class="meg_a_1">
            <div class="center_b_2 dongduRed">此设置会覆盖本门店下所有会员设置，请慎重！</div>
            <div class="center_b_3">
              <ul>
                <li class="up"><span>门店名称：</span>{{storeVip.storeName}}</li>
                <li class="up"><span class="import">会员范围：</span>
                  <label><input type="radio" :value="1" v-model="storeVip.huiyuanfanwei"/>连锁</label>
                  <label><input type="radio" :value="2" v-model="storeVip.huiyuanfanwei"/>单店</label>
                  <label><input type="radio" :value="0" v-model="storeVip.huiyuanfanwei"/>不修改</label>
                </li>
                <li class="up"><span class="import">通知方式：</span>
                  <select v-model="storeVip.tongzhileixing">
                    <option :value="0">不修改</option>
                    <option v-for="item in tongzhileixing" :key="item.id" :value="item.id">{{item.name}}</option>
                  </select>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="meg_c"><button type="button" class="off" @click="vipShow=false">取消</button><button type="button" class="sub" @click="addStoreVip">确定</button></div>
        <div class="meg_cld" @click="vipShow=false"><i class="icon-x"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
import Info from './Info.vue'
import Pay from '@/components/pay'
import axios from '@/http/axios'
import { changeStr } from "@/utils/util";
import { tongzhileixing } from "@/data/data";
import moment from 'moment';
export default {
  name: 'storeManagement',
  data() {
    return {
      title: '',
      tongzhileixing,
      SonId:0,
      storeVip:{
        huiyuanfanwei:0,
        tongzhileixing:0,
      },
      Store:{},
      infoShow: false,
      payShow:false,
      StoreAdd:true,
      vipShow:false,
      itemName:"SMS",
      dataList:[],
      form:{
        storeName:'',
        storeId:'',
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      MmsData:{
        new:true,
      },
    }
  },
  created() {this.getData()},
  components: {Info,Pay},
  mounted() {
  },
  methods: {
    addSMS(item){
      this.SonId=0
      this.itemName="SMS"
      this.Store=item
      this.payShow=true
    },
    payStore(item){
      if(this.$store.state.User.daoqishijian>=0) this.SonId=22
      else this.SonId=21
      this.itemName="Activate"
      this.Store=item
      this.payShow=true
    },
    countDate(date){
      if(moment(date).diff(moment(this.$store.state.User.date),'days')<30) return 'eme'
      else return ''
    },
    batchSetting(item){
      this.storeVip={
        id:item.id,
        storeName:item.storeName,
        huiyuanfanwei:0,
        tongzhileixing:0,
      }
      this.vipShow=true
    },
    getDate(date){
      if(date) return moment(date).format("YYYY-MM-DD")
      else return ''
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    Str(str){
      if(str) return changeStr(str)
      return '无'
    },
    setInfoData(item){
      this.title=item.storeName
      this.MmsData.new=false
      this.MmsData.data=item
      this.infoShow=true
    },
    addStoreVip(){
      if(this.$store.state.User.daoqishijian>=0){
        const{storeName,huiyuanfanwei,tongzhileixing}=this.storeVip
        let fwStr=huiyuanfanwei==0?'':huiyuanfanwei==1?"会员范围设为连锁":"会员范围设为单店"
        let lxStr=tongzhileixing==0?'':tongzhileixing==1?"通知方式设置为短信":tongzhileixing==2?"通知方式设置为公众号":"通知方式设置为短信+公众号"
        if(!fwStr&&!lxStr){
          this.$Mesg({content:"您没有任何更改信息",errOrSuc:'err'})
          return
        }
        this.$trueOrFalse({
          title: '提示信息',
          content: `您确认将门店${storeName}的全部会员的${fwStr} ${lxStr}?`,
          torf: true,
          suc: ()=>{
            axios.post('/store/setStroeVip',this.storeVip).then((res) => {
              if(res.code===5000){
                this.$Mesg({content: res.msg})
                this.vipShow=false
              }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
            })
          },
        })
      }else this.$Mesg({content:'门店未开通VIP',errOrSuc: 'err'})
      
    },
    newStoreAdd(){
      this.StoreAdd=false
      axios.post('/store/getMaxStore').then((res) => {
        if(res.code===5000){
          const {currentStore,maxStore} =res.data
          if(parseInt(currentStore)<parseInt(maxStore)){
            this.title="新增门店"
            this.MmsData.new=true
            this.infoShow=true
          }else{
            this.$trueOrFalse({
              title: '提示信息',
              content: '您的门店数量已达上限，是否需要扩充分店？',
              torf: true,
              suc: ()=>{
                let {fenpeimendianId,fenpeimendianName}=JSON.parse(sessionStorage.getItem('dongduUser')).user
                this.SonId=23
                this.itemName="Activate"
                this.Store={
                  id:fenpeimendianId,
                  storeName:fenpeimendianName
                }
                this.payShow=true
              },
            })
          }
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
        this.StoreAdd=true
      })
    },
    closedWindow(){
      this.infoShow=false
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/store/listPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    }
  },
}
</script>
